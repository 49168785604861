
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { MainLayout, SubpageHeader, createMetaData } from '../components';

import { BlogList, BlogPagination } from '../components/blog';

const BlogTemplate = (props) => {
	const { pageBlogJson: json } = useStaticQuery(graphql`
		query {
			pageBlogJson {
				meta_title
				meta_description
				meta_keywords
				title
				permalink
			}
		}
	`);

	const { site, page, seo } = createMetaData(json);

	const { blogPageData: pageData } = props.pageContext;

	seo.canonicalUrl = `${site.siteMetadata.canonicalUrl}${pageData.path}`;

	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<div>
				<section id='blog'>
					<div className='container'>
						<BlogList site={site} pageData={pageData} />
						<BlogPagination pageData={pageData} />
					</div>
				</section>
			</div>
		</MainLayout>
	);
};

export default BlogTemplate;
